import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  CardContent,
  Grid,
  Link,
  Button as MuiButton,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing, display } from "@mui/system";
import agent from "../../utils/axios";
import { Authenticate } from "../../types/Authenticate";

interface AuthUrlResponse {
  url: string;
}

const Card = styled(MuiCard)`
  ${spacing};

  box-shadow: none;
`;

const Divider = styled(MuiDivider)(spacing);

const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Button = styled(MuiButton)(spacing);

const Typography = styled(MuiTypography)(display);

type PayeeDataSchema = {
  [key: string]: any;
  id: string;
  name: {
    first: string;
    middle: string;
    last: string;
  };
  mailingAddress: {
    address1: string;
    address2: string;
    address3: string;
    city: string;
    state: string;
    postalCode: string;
  };
  phone: {
    home: string;
    cell: string;
  };
  tin: string;
  last4: string;
  email: string;
  type: string;
  paymentMethod: string;
  payable: boolean;
  bills: [
    {
      requestingApplication: string;
      applicantId: string | null;
      requestingClient: string;
      programName: string;
      amount: number;
      paymentMethod: string;
      status: string;
      invoiceDate: string;
      paymentService: {
        billDate: string;
        paymentDate: string;
        paymentStatus: string;
        billRefCode: string;
        paymentRefCode: string;
      };
      address: {
        address1: string;
        address2: string;
        address3: string;
        city: string;
        state: string;
        postalCode: string;
      };
    }
  ];
};

function BillDetails() {
  const { billRefCode } = useParams();
  const [payeeData, setPayeeData] = useState<PayeeDataSchema | null>(null);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    async function getPayee() {
      try {
        setLoading(true);
        const response = await agent.Bills.getBillByRefCode(billRefCode ?? "");
        setPayeeData(response);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching payee:", error);
        setLoading(false);
      }
    }

    getPayee();
  }, [billRefCode]);

  const handleAuthCode = async (code: string | null, redirect_uri: string) => {
    if (code) {
      try {
        const authRequest: Authenticate = { code };

        await agent.quickbooks.authenticateQuickbooks(
          authRequest,
          redirect_uri
        );
        await agent.Bills.convertToCheck(billRefCode ?? "");
        window.location.reload();
      } catch (error) {
        console.error("Error processing QuickBooks authentication:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleConvertToCheck = async () => {
    setLoading(true);
    try {
      const { url }: AuthUrlResponse = await agent.quickbooks.getAuthUrl();
      if (url) {
        const urlParams = new URLSearchParams(new URL(url).search);
        const redirect_uri = urlParams.get("redirect_uri") ?? "";
        const popupWidth = 600;
        const popupHeight = 700;
        const left = window.screen.width / 2 - popupWidth / 2;
        const top = window.screen.height / 2 - popupHeight / 2;

        const authWindow = window.open(
          url,
          "QuickBooks Login",
          `width=${popupWidth},height=${popupHeight},left=${left},top=${top}`
        );

        // Check if the popup opened successfully
        if (!authWindow) {
          setLoading(false);
          return;
        }

        const authCheckInterval = setInterval(() => {
          try {
            if (authWindow.location.href.includes("code=")) {
              const params = new URLSearchParams(authWindow.location.search);
              const code = params.get("code");
              if (code) {
                clearInterval(authCheckInterval);
                authWindow.close();
                handleAuthCode(code, redirect_uri);
              }
            }
          } catch (error) {
            // Ignore cross-origin errors until the popup is redirected to your domain with the code.
          }

          if (authWindow.closed) {
            clearInterval(authCheckInterval);
          }
        }, 200);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  if (loading) {
    // Data is still being fetched, show a loading indicator or return null
    return <div>Loading...</div>;
  }
  return (
    <React.Fragment>
      <Helmet title="Bill Details" />

      <Typography variant="h3" gutterBottom display="inline">
        Bill #{billRefCode}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/bills">
          Bills
        </Link>
        <Typography>Details</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container justifyContent="center">
        <Grid item xs={12} lg={10}>
          <Shadow>
            <Card px={6} pt={6}>
              <CardContent>
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <Typography variant="body2" gutterBottom>
                      This is the receipt for a payment of{" "}
                      {payeeData?.bills[0].amount.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}{" "}
                      (USD) made to {payeeData?.name.first}{" "}
                      {payeeData?.name.last}.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="caption">Payment Ref Code.</Typography>
                    <Typography variant="body2">
                      {payeeData?.bills[0].paymentService?.paymentRefCode}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="caption" align="right" display="block">
                      Payment Date
                    </Typography>
                    <Typography variant="body2" align="right">
                      {new Intl.DateTimeFormat("en-US", {
                        dateStyle: "medium",
                        timeStyle: "short",
                      }).format(
                        payeeData?.bills[0]?.paymentService.paymentDate
                          ? new Date(
                              payeeData.bills[0].paymentService.paymentDate
                            )
                          : undefined
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="caption">Payment To:</Typography>
                    <Typography variant="body2">
                      {payeeData?.name.first} {payeeData?.name.middle}{" "}
                      {payeeData?.name.last}
                      <br />
                      {payeeData?.bills[0].address.address1}{" "}
                      {payeeData?.bills[0].address.address2}
                      <br />
                      {payeeData?.bills[0].address.city}
                      <br />
                      {payeeData?.bills[0].address.state}
                      <br />
                      {payeeData?.bills[0].address.postalCode}
                      <br />
                      <Link href={`mailto:${payeeData?.email}`}>
                        {payeeData?.email}
                      </Link>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="caption" align="right" display="block">
                      Client
                    </Typography>
                    <Typography variant="body2" align="right">
                      {payeeData?.bills[0].requestingApplication}
                      <br />
                      {payeeData?.bills[0].requestingClient}
                      <br />
                      {payeeData?.bills[0].programName}
                    </Typography>
                    <br />
                    <Typography variant="caption" align="right" display="block">
                      Payment Type
                    </Typography>
                    <Typography variant="body2" align="right">
                      {" "}
                      {payeeData?.bills[0]?.paymentMethod}
                    </Typography>
                    <Typography
                      variant="caption"
                      align="right"
                      display="block"
                      mt={2}
                    >
                      Payment Status
                    </Typography>
                    <Typography variant="body2" align="right">
                      {" "}
                      {payeeData?.bills[0]?.paymentService.paymentStatus}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card px={6}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Program</TableCell>
                    <TableCell />
                    <TableCell align="right">Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{payeeData?.bills[0].programName}</TableCell>
                    <TableCell />
                    <TableCell align="right">
                      {payeeData?.bills[0].amount.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell />
                    <TableCell>Total</TableCell>
                    <TableCell align="right">
                      {payeeData?.bills[0].amount.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Card>
            <Card pb={6} px={6}>
              <CardContent style={{ textAlign: "center" }}>
                {payeeData?.bills[0]?.paymentMethod.toLowerCase() === "ach" ? (
                  <Button
                    variant="contained"
                    color="primary"
                    mt={2}
                    onClick={handleConvertToCheck}
                  >
                    Convert to Check
                  </Button>
                ) : null}
              </CardContent>
            </Card>
          </Shadow>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default BillDetails;
